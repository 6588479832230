var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-content terms-and-conditions"},[_c('div',{staticClass:"head"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Terms_of_use")))])]),(!_vm.settings.conditions)?_c('div',[_c('center',[_c('h1',[_vm._v("no conditions found")])])],1):_vm._e(),(_vm.settings.conditions)?_c('div',{staticClass:"Tsection-01 position-relative"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('h4',{domProps:{"textContent":_vm._s(
              _vm.locale == 'ar'
                ? _vm.settings.conditions[0].title_ar
                : _vm.settings.conditions[0].title_en
            )}}),_c('p',{domProps:{"textContent":_vm._s(
              _vm.locale == 'ar'
                ? _vm.settings.conditions[0].paragraph_ar
                : _vm.settings.conditions[0].paragraph_en
            )}}),_c('h4',{domProps:{"textContent":_vm._s(
              _vm.locale == 'ar'
                ? _vm.settings.conditions[1].title_ar
                : _vm.settings.conditions[1].title_en
            )}}),_c('p',{domProps:{"textContent":_vm._s(
              _vm.locale == 'ar'
                ? _vm.settings.conditions[1].paragraph_ar
                : _vm.settings.conditions[1].paragraph_en
            )}})])])]):_vm._e(),(_vm.settings.conditions)?_c('div',{staticClass:"Tsection-02 position-relative"},[_c('div',{staticClass:"decor"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},_vm._l((_vm.settings.conditions.slice(
                2,
                _vm.settings.conditions.length
              )),function(condition,index){return _c('div',{key:index,staticClass:"item"},[_c('h3',{domProps:{"textContent":_vm._s(
                  _vm.locale == 'ar' ? condition.title_ar : condition.title_en
                )}}),_c('p',{domProps:{"textContent":_vm._s(
                  _vm.locale == 'ar'
                    ? condition.paragraph_ar
                    : condition.paragraph_en
                )}})])}),0),_vm._m(0)])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('figure',[_c('img',{attrs:{"src":require("@/main/assets/images/54c5989ec7bc8b192c60f9e9a0dae937.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }